import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./index.css";
import App from "./App";
import Contact from "./Pages/Contact";
import Reviews from "./Pages/Reviews";
import Pay from "./Pages/Pay";
import Shop from "./Pages/Shop";
import Signup from "./Pages/signup";
import Signin from "./Pages/signin";
import About from "./Pages/About";
import Clients from "./Pages/Clients";
import Cart from "./Pages/Cart";
import Profile from "./Pages/Profile";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="/pay" element={<Pay />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signin" element={<Signin />} />
      <Route path="/about-company" element={<About />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/profile" element={<Profile />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
