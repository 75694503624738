import Layout from "../Layout";
import Fixed from "../Components/Fixed";
import PayComponent from "../Components/Pay"

function Pay() {
  return (
    <Layout>
      <PayComponent />
      <Fixed />
    </Layout>
  );
}

export default Pay;
