import Layout from "../Layout";
import Fixed from "../Components/Fixed";
import SigninComponent from "../Components/Signin";

function Signin() {
  return (
    <Layout>
      <SigninComponent />
      <Fixed />
    </Layout>
  );
}

export default Signin;
