const Profile = () => {
  return (
    <>
      <section className="pt-50">
        <div className="container">
          <h2 className="mvh-secfooter">Your Profile</h2>
          <h3 className="dtrp-secfooter">Your Innocent Panda Profile .</h3>
          <h3 className="dtrp-secfooter">
            Manage Your Account's , Shipping Addresses & Payment Options.
          </h3>
          <h3 className="dtrp-secfooter">We autosave your setting just type</h3>
          <div className="d-flex justify-content-center mt-4 mb-3">
            <label className="profile-mig">
              <img
                src="https://radiustheme.com/demo/html/cirkle/media/team/team_7.jpg"
                alt=""
              />
              <input type="file" />
            </label>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="anme-lb">Name *</label>
                </div>
                <input id="anme-lb" type="text" placeholder="Your Full Name" />
              </div>
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="email-lb">Email *</label>
                </div>
                <input
                  id="email-lb"
                  type="text"
                  placeholder="Your Email Address"
                />
              </div>
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="phone-lb">Phone *</label>
                </div>
                <input
                  id="phone-lb"
                  type="text"
                  placeholder="+(92) 000 0000 000"
                />
              </div>
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="whatsapp-lb">Whatsapp Number *</label>
                </div>
                <input
                  id="whatsapp-lb"
                  type="text"
                  placeholder="+(00) 000 0000 000"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="city-lb">City *</label>
                </div>
                <select id="city-lb">
                  <option>Karachi</option>
                </select>
              </div>
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="zip-lb">ZIP Code *</label>
                </div>
                <input
                  id="zip-lb"
                  type="text"
                  placeholder="Enter Your Area Zip Code"
                />
              </div>
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="street-lb">Street *</label>
                </div>
                <input
                  type="text"
                  id="street-lb"
                  placeholder="Enter Your Full Street Address"
                />
              </div>
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="street-lb">House Number *</label>
                </div>
                <input
                  type="text"
                  id="street-lb"
                  placeholder="Enter Your House Number"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="street-lb">
                    More Detailed Address (Optional)
                  </label>
                </div>
                <textarea rows={4} placeholder="Enter More Detailed Address To Help us to Deliver Your Order."></textarea>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Profile;
