import { Link } from "react-router-dom";
const Fixed = () => {
  return (
    <>
      <div className="s-ntbs">
        <Link to="/cart">
          <button className="hov-svg" title="Your Cart">
            <svg
              x="0px"
              y="0px"
              viewBox="0 0 24 24"
              height={`16px`}
              fill="#ee2852"
            >
              <circle cx="9" cy="21" r="2"></circle>
              <circle cx="20" cy="21" r="2"></circle>
              <path
                d="M23.8,5.4C23.6,5.1,23.3,5,23,5H6.8L6,0.8C5.9,0.3,5.5,0,5,0H1C0.4,0,0,0.4,0,1s0.4,1,1,1h3.2L5,6.2c0,0,0,0.1,0,0.1
                          l1.7,8.3C7,16,8.2,17,9.6,17c0,0,0,0,0.1,0h9.7c1.5,0,2.7-1,3-2.4L24,6.2C24,5.9,24,5.6,23.8,5.4z"
              ></path>
            </svg>
          </button>
        </Link>
        <Link to="/profile">
          <button className="hov-path" title="Your Profile">
            <svg
              height={`16px`}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                stroke="#ee2852"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                stroke="#ee2852"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </Link>
      </div>
      <div className="review-ntb">
        <Link to="/reviews">
          <button title="Our Reviews">
            <svg viewBox="0 0 24 24" fill="none" height={`12px`}>
              <path
                d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
                stroke="#fff"
                fill="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
            REVIEWS
          </button>
        </Link>
      </div>
    </>
  );
};
export default Fixed;
