import Layout from "../Layout";
import Fixed from "../Components/Fixed";
import AboutComponent from "../Components/About";

function About() {
  return (
    <Layout>
      <AboutComponent />
      <Fixed />
    </Layout>
  );
}

export default About;
