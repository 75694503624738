import Layout from "../Layout";
import Fixed from "../Components/Fixed";
import ContactComponent from "../Components/Contact"

function Contact() {
  return (
    <Layout>
      <ContactComponent />
      <Fixed />
    </Layout>
  );
}

export default Contact;
