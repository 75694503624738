import { Link } from "react-router-dom";
const Clients = () => {
  const array = [
    {
      name: "Vidcity",
      img: "https://vidcity.co/images/share.jpg",
      url: "https://vidcity.co",
    },
    {
      name: "The Creative Institute",
      img: "https://tci.com.pk/images/LOGO.jpg",
      url: "https://tci.com.pk",
    },
    {
      name: "Digibytes",
      img: "https://digibytes.pk/wp-content/uploads/2022/10/cropped-Digibytes-01.png",
      url: "https://digibytes.pk",
    },
  ];
  const clients = array.map((v, i) => {
    return (
      <div
        className="col-slide-pb col-lg-4 col-xl-3 col-md-6 col-sm-12"
        key={i}
      >
        <div className="paperx-box">
          <svg
            className="qodef-woo-svg"
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="100%"
              height="100%"
              fill="none"
              rx="8"
              ry="8"
              stroke="#ff1949"
              strokeWidth="1"
              strokeDasharray="10"
              strokeLinecap="square"
            ></rect>
          </svg>
          <div className="paper-img-prod client-img">
            <a>
              <img alt="" className="paper-product" src={v.img} />
            </a>
          </div>
          <h2 className="paper-prod-naem">{v.name}</h2>
          <Link to={v.url} target="_blank" className="psr">
            <p className="paper-prod-para">{v.url}</p>
          </Link>
        </div>
      </div>
    );
  });
  return (
    <>
      <section className="pt-50">
        <div className="container">
          <h2 className="mvh-secfooter">Clients</h2>
          <h3 className="dtrp-secfooter">
            Top Companies that use Our Products
          </h3>
          <div className="paperx-product-swipe pt-20 ">
            <div className="row">{clients}</div>
          </div>
        </div>
        <div class="cta-area white-bg section-padding">
          <div class="container">
            <div class="row text-center">
              <div class="col-xs-12 col-sm-10 col-md-8 column-center">
                <div class="cta-text">
                  <h1 class="mb-45">
                    Using Our Products ? Feel Free To List
                    <br />
                    <span class="theme-color">Your Company</span>
                  </h1>
                  <button class="btn rt-btn2">List Your Company</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Clients;
