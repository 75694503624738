import Layout from "../Layout";
import Fixed from "../Components/Fixed";
import ProfileComponent from "../Components/Profile"

function Profile() {
  return (
    <Layout>
      <ProfileComponent />
      <Fixed />
    </Layout>
  );
}

export default Profile;
