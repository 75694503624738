const Reviews = () => {
  const array = [1, 2, 3, 4, 5, 6];
  const reviews = array.map((value, index) => {
    return (
      <div className="col-xl-3 col-lg-4 col-md-6 vid-bixox" key={index}>
        <div className="paperx-box video-box-paox">
          <div className="paper-img-prod video-paper-xo">
            <video
              src="https://previews.customer.envatousercontent.com/359ac8b1-abde-48fe-8011-c45b8046079a/watermarked_preview/watermarked_preview.mp4"
              autoPlay
              loop
              muted
            ></video>
          </div>
          <h2 className="paper-prod-naem">Muhammad Hassaan</h2>
          <p className="paper-prod-para">mhassaan@gmail.com</p>
          <div className="review-wraper">
            <button className="play-btn-paper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-play"
                viewBox="0 0 16 16"
              >
                <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  });
  return (
    <>
      <section className="pt-50">
        <div className="container">
          <h2 className="mvh-secfooter">
            Reviews
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="25"
              viewBox="0 0 24 24"
              fill="rgb(251 191 36/1)"
              aria-hidden="true"
              className="w-5 h-5 pb-[1px] text-amber-400"
            >
              <path
                fillRule="evenodd"
                d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                clipRule="evenodd"
              ></path>
            </svg>
          </h2>
          <h3 className="dtrp-secfooter">Our Happy Customers Reviews.</h3>
          <div className="row paperx-product-swipe payboxes-mar pt-20">
            {reviews}
          </div>
        </div>
        <div class="cta-area white-bg section-padding">
          <div class="container">
            <div class="row text-center">
              <div class="col-xs-12 col-sm-10 col-md-8 column-center">
                <div class="cta-text">
                  <h1 class="mb-45">
                    We would love to list <br />
                    <span class="theme-color">Your Review</span>
                  </h1>
                  <button class="btn rt-btn2">List Your Review</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Reviews;
