const Signup = () => {
  return (
    <>
      <section className="pt-50">
        <div className="container">
          <h2 className="mvh-secfooter">Signup</h2>
          <h3 className="dtrp-secfooter">
            Welcome To The Innocent Panda . Signup Now .
          </h3>
          <div className="row pt-50">
            <div className="col-lg-6">
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="name-lb">Name</label>
                </div>
                <input id="name-lb" type="text" placeholder="Your Name" />
              </div>
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="email-lb">Email</label>
                </div>
                <input id="email-lb" type="text" placeholder="Your Email" />
              </div>
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="phone-lb">Phone</label>
                </div>
                <input
                  id="phone-lb"
                  type="text"
                  placeholder="Your Phone Number"
                />
              </div>
              <div className="box-con-frm">
                <div className="lab-bcfrm">
                  <label htmlFor="pass-lb">Password</label>
                </div>
                <input
                  id="pass-lb"
                  type="password"
                  placeholder="Enter Strong Password"
                />
              </div>
              <div>
                <div className="slider_ntbs">
                  <button className="graytntbs">Signup</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Signup;
