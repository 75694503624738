const Signup = () => {
    return (
      <>
        <section className="pt-50">
          <div className="container">
            <h2 className="mvh-secfooter">Signin</h2>
            <h3 className="dtrp-secfooter">
              Fancy a cheeky chat? Get in touch. Don't worry, we're a friendly
              bunch.
            </h3>
            <div className="row pt-50">
              <div className="col-lg-6">
                <div className="box-con-frm">
                  <div className="lab-bcfrm">
                    <label htmlFor="email-lb">Email</label>
                  </div>
                  <input id="email-lb" type="text" placeholder="Your Email" />
                </div>
                <div className="box-con-frm">
                  <div className="lab-bcfrm">
                    <label htmlFor="phone-lb">Password</label>
                  </div>
                  <input
                    id="phone-lb"
                    type="password"
                    placeholder="Type away, we aren’t looking ;)"
                  />
                </div>
                <div>
                  <div className="slider_ntbs">
                    <button className="graytntbs">Signin</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
  export default Signup;
  