import Layout from "../Layout";
import Fixed from "../Components/Fixed";
import Products from "../Components/products";

function Shop() {
  return (
    <Layout>
      <Products />
      <Fixed />
    </Layout>
  );
}

export default Shop;
