import { useState } from "react";
import Jazzcash from "./PayForms/jazzcash";
import OtherBank from "./PayForms/other-bank";

const Cart = () => {
  const array = [
    {
      name: "Vidcity",
      img: "https://vidcity.co/images/share.jpg",
      url: "https://vidcity.co",
    },
    {
      name: "The Creative Institute",
      img: "https://tci.com.pk/images/LOGO.jpg",
      url: "https://tci.com.pk",
    },
    {
      name: "Digibytes",
      img: "https://digibytes.pk/wp-content/uploads/2022/10/cropped-Digibytes-01.png",
      url: "https://digibytes.pk",
    },
  ];
  const cartItems = array.map((v, i) => {
    return (
      <div
        className="col-slide-pb col-lg-4 col-xl-3 col-md-6 col-sm-12"
        key={i}
      >
        <div className="paperx-box">
          <svg
            className="qodef-woo-svg"
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="100%"
              height="100%"
              fill="none"
              rx="8"
              ry="8"
              stroke="#ff1949"
              strokeWidth="1"
              strokeDasharray="10"
              strokeLinecap="square"
            ></rect>
          </svg>
          <div className="paper-img-prod">
            <div className="paping-quan">
              <div className="papimg-quan-abs">Quantity : 1</div>
              <div className="papimg-quan-abs">Price : 100</div>
            </div>
            <img
              className="paper-product"
              alt=""
              src="https://cdn.shopify.com/s/files/1/0506/9602/8329/products/kitchenroll10_360x.png?v=1664550901"
            />
          </div>
          <h2 className="paper-prod-naem">Bamboo Kitchen Roll Bulk Box</h2>
          <p className="paper-prod-para">10 Rolls New 2k23</p>
          <div className="d-flex justify-content-between align-items-center">
            <div className="price-prod-paperx">
              <span>Rs</span>100
            </div>
            <div className="prod-review-paperx">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-5 h-5 pb-[1px] text-amber-400"
              >
                <path
                  fillRule="evenodd"
                  d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>5</span> (<span>10</span> reviews)
            </div>
          </div>
          <div className="pap-btns-cart">
            <button className="added-to-cart">
              <svg viewBox="0 0 9 9" height={`18px`} fill="none">
                <path
                  d="M2.99997 4.125C3.20708 4.125 3.37497 4.29289 3.37497 4.5C3.37497 5.12132 3.87865 5.625 4.49997 5.625C5.12129 5.625 5.62497 5.12132 5.62497 4.5C5.62497 4.29289 5.79286 4.125 5.99997 4.125C6.20708 4.125 6.37497 4.29289 6.37497 4.5C6.37497 5.53553 5.5355 6.375 4.49997 6.375C3.46444 6.375 2.62497 5.53553 2.62497 4.5C2.62497 4.29289 2.79286 4.125 2.99997 4.125Z"
                  fill="currentColor"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.37497 2.625H7.17663C7.76685 2.625 8.25672 3.08113 8.29877 3.66985L8.50924 6.61641C8.58677 7.70179 7.72715 8.625 6.63901 8.625H2.36094C1.2728 8.625 0.413174 7.70179 0.490701 6.61641L0.70117 3.66985C0.743222 3.08113 1.23309 2.625 1.82331 2.625H2.62497L2.62497 2.25C2.62497 1.21447 3.46444 0.375 4.49997 0.375C5.5355 0.375 6.37497 1.21447 6.37497 2.25V2.625ZM3.37497 2.625H5.62497V2.25C5.62497 1.62868 5.12129 1.125 4.49997 1.125C3.87865 1.125 3.37497 1.62868 3.37497 2.25L3.37497 2.625ZM1.82331 3.375C1.62657 3.375 1.46328 3.52704 1.44926 3.72328L1.2388 6.66985C1.19228 7.32107 1.70805 7.875 2.36094 7.875H6.63901C7.29189 7.875 7.80766 7.32107 7.76115 6.66985L7.55068 3.72328C7.53666 3.52704 7.37337 3.375 7.17663 3.375H1.82331Z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>
            <button className="added-to-whish">
              <svg className="" viewBox="0 0 24 24" fill="none" height={`18px`}>
                <path
                  d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
                  stroke="currentColor"
                  fill=""
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  });
  const [PaySelect, SetSelect] = useState([
    {
      name: "Cash On Delievery",
      key: 0,
    },
    {
      name: "JazzCash",
      key: 1,
    },
    {
      name: "Bank Alfalah",
      key: 2,
    },
    {
      name: "Meezan Bank",
      key: 3,
    },
    {
      name: "USDT (Crypto Currency)",
      key: 4,
    },
  ]);
  const [pay_type, Setpaytype] = useState(0);
  const [payForm, SetpayForm] = useState(" You'r Paying Cash On Delievery.");
  function init_form(e) {
    const select = parseInt(e.target.value);
    Setpaytype(parseInt(e.target.value));
    if (select === 0) {
      SetpayForm(" You'r Paying Cash On Delievery.");
    } else if (select === 1) {
      SetpayForm(<Jazzcash />);
    } else {
      SetpayForm(<OtherBank Select={select}/>);
    }
  }
  return (
    <>
      <section className="pt-50">
        <div className="container">
          <h2 className="mvh-secfooter">Your Cart</h2>
          <h3 className="dtrp-secfooter">
            Here Are The Items You Have Added In Your Cart
          </h3>
          <div className="paperx-product-swipe pt-20">
            <div className="row">{cartItems}</div>
            <div className="totl-nod d-flex justify-content-between flex-wrap">
              <div>Total</div>
              <div>Rs : 1500</div>
            </div>
            <div className="box-con-frm">
              <div className="lab-bcfrm">
                <label htmlFor="phone-lb">Payment Method</label>
              </div>
              <select defaultValue={pay_type} onChange={(e) => init_form(e)}>
                {PaySelect.map((address, key) => (
                  <option key={key} value={address.key}>
                    {address.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="init-payment mb-5 pt-2">{payForm}</div>
            <div className="box-con-frm">
              <div className="lab-bcfrm">
                <label htmlFor="mess-lb">Any Note For Order</label>
              </div>
              <textarea
                id="mess-lb"
                type="text"
                rows={4}
                placeholder="Feel Free To write any message or notes for delievery."
              ></textarea>
            </div>
            <div className="note-ship">
              Info : Your Shipping Details are Saved in your profile.
            </div>
            <div className="note-ship mb-5">Payment : Not Paid.</div>
            <div className="mb-5">
              <button className="btn rt-btn2">Place Order</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Cart;
