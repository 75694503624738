const OtherBank = ({ Select }) => {
  return (
    <>
      <section>
        <div className="container">
          <div>
            Send Rs : 2000 To This Account Number and Attach The Screenshot Or
            Enter Transaction ID Below To Verify Your Payment.
          </div>
          {Select === 2 ? (
            <>
              <div>
                <b>Bank : </b> Bank Alfalah
              </div>
              <div>
                <b>Account Id : </b> 324333434334334
              </div>
              <div>
                <b>Account Title : </b> MUHAMMAD UZAIR
              </div>
            </>
          ) : Select === 3 ? (
            <>
              <div>
                <b>Bank : </b> Meezan Bank
              </div>
              <div>
                <b>Account Id : </b> 324333434334334
              </div>
              <div>
                <b>Account Title : </b> MUHAMMAD UZAIR
              </div>
            </>
          ) : Select === 4 ? (
            <>
              <div>
                <b>Crypto Currency : </b> USDT
              </div>
              <div>
                <b>Binance Pay Id : </b> 123456
              </div>
              <div>
                <b>Account Title : </b> MUHAMMAD UZAIR
              </div>
            </>
          ) : (
            ""
          )}
          <div className="box-con-frm">
            <div className="lab-bcfrm">
              <label htmlFor="phone-lb">Attach Screenshot</label>
            </div>
            <input type="file" />
          </div>
          <div>OR</div>
          <div className="box-con-frm">
            <div className="lab-bcfrm">
              <label htmlFor="phone-lb">Enter Transaction ID</label>
            </div>
            <input type="text" />
          </div>
        </div>
      </section>
    </>
  );
};
export default OtherBank;
