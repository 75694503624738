import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Layout = (props) => {
  const location = useLocation();
  const pathName = location.pathname;
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="top-badge">
        Free Delievery on all order's over Rs 1000
      </div>
      <header>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark nav-moed">
            <div className="container-fluid pl-0">
              <Link className="navbar-brand logo-web-header" to={`/`}>
                <img src="/assets/logo.jpg" alt="" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mynavbar"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="mynavbar">
                <ul className="navbar-nav me-auto justify-content-right w-100">
                  <li
                    className={`nav-item ${pathName === "/" ? "active" : ""}`}
                  >
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      pathName === "/shop" ? "active" : ""
                    }`}
                  >
                    <Link className="nav-link" to="/shop">
                      Shop
                    </Link>
                  </li>
                  <li className="nav-item label-nav-item d-none">
                    <span>New</span>
                    <Link className="nav-link" to="/explore">
                      Explore
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      pathName === "/contact" ? "active" : ""
                    }`}
                  >
                    <Link className="nav-link" to="/contact">
                      Contact
                    </Link>
                  </li>
                  <li className="nav-item label-nav-item d-none">
                    <span>Deal</span>
                    <Link className="nav-link" to="">
                      Subscribe & Save
                    </Link>
                  </li>
                  <div className="li-nav-cino">
                    <li className="nav-item mr-5 ml-5">
                      <button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19.237"
                          height="19.56"
                          viewBox="0 0 19.237 19.56"
                        >
                          <defs></defs>
                          <g className="a">
                            <ellipse
                              className="b"
                              cx="8.333"
                              cy="8.333"
                              rx="8.333"
                              ry="8.333"
                            ></ellipse>
                            <ellipse
                              className="c"
                              cx="8.333"
                              cy="8.333"
                              rx="7.583"
                              ry="7.583"
                            ></ellipse>
                          </g>
                          <line
                            className="a"
                            x2="3.942"
                            y2="4.265"
                            transform="translate(14.235 14.235)"
                          ></line>
                        </svg>
                      </button>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {props.children}
      <section className="pt-50 pb-50 top-box-shadow">
        <div className="container">
          <h2 className="mvh-secfooter">Some More Links For Visit</h2>
          <h3 className="dtrp-secfooter">Discover Shop</h3>
          <div className="row pt-50">
            <div className="col-lg-4">
              <ul className="mvh-ul-foot">
                <li>
                  <Link to="/about-company">About Company</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/reviews">Reviews</Link>
                </li>
                <li>
                  <Link to="/clients">Clients</Link>
                </li>
                <li>
                  <Link to="/pay">Pay</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul className="mvh-ul-foot">
                <li>
                  <a href="https://api.whatsapp.com/send?phone=923231389030">
                    Whatsapp Chat
                  </a>
                </li>
                <li>
                  <a href="https://messenger.com/paperxbox">FB Messenger</a>
                </li>
                <li>
                  <Link to="tel:+(92)3231389030">+(92)3231389030</Link>
                </li>
                <li>
                  <Link to="mailto:247@theinnocentpanda.com">
                    247@theinnocentpanda.com
                  </Link>
                </li>
                <li>
                  <Link to="mailto:owner@theinnocentpanda.com">
                    owner@theinnocentpanda.com
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul className="mvh-ul-foot">
                <li className="d-none">
                  <Link to="/explore">Explore</Link>
                </li>
                <li>
                  <Link to="/shop">Shop</Link>
                </li>
                <li>
                  <Link to="/signup">Signup</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="top-badge">
        2k23 theinnocentpanda.com. All rights reserved
      </div>
    </>
  );
};
export default Layout;
