const Jazzcash = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="box-con-frm">
            <div className="lab-bcfrm">
              <label htmlFor="phone-lb">JazzCash Phone Number</label>
            </div>
            <input type="text" placeholder="Enter You JazzCash Phone Number" />
          </div>
          <div className="box-con-frm">
            <div className="lab-bcfrm">
              <label htmlFor="phone-lb">LAST 6 DIGITS OF CNIC</label>
            </div>
            <input
              type="text"
              placeholder="Enter Last 6 Digit's Of Your CNIC"
            />
          </div>
          <button className="btn rt-btn2">Continue Payment</button>
        </div>
      </section>
    </>
  );
};
export default Jazzcash;
