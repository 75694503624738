import Layout from "../Layout";
import Fixed from "../Components/Fixed";
import ClientsComp from "../Components/clients";

function Clients() {
  return (
    <Layout>
      <ClientsComp />
      <Fixed />
    </Layout>
  );
}

export default Clients;
