const Pay = () => {
  const array = [
    {
      url: "/pay/Jazz-cash.jpg",
      name: "Jazz Cash",
      number: "+923231389030",
    },
    {
      url: "/pay/Bank_alfalah.png",
      name: "Bank Alfalah",
      number: "89076543213576",
    },
    {
      url: "/pay/meezan.webp",
      name: "Meezan Bank",
      number: "89076543213576",
    },
    {
      url: "/pay/USDT.jfif",
      name: "USDT",
      number: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    },
  ];
  const methods = array.map((method, index) => {
    return (
      <div className="paperx-box" key={index}>
        <svg
          className="qodef-woo-svg"
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="100%"
            height="100%"
            fill="none"
            rx="8"
            ry="8"
            stroke="#ff1949"
            strokeWidth="1"
            strokeDasharray="10"
            strokeLinecap="square"
          ></rect>
        </svg>
        <div className="paper-img-prod">
            <img className="paper-product" src={method.url} alt=""/>
        </div>
        <h2 className="paper-prod-naem">{method.name}</h2>
        <p className="paper-prod-para">{method.number}</p>
      </div>
    );
  });
  return (
    <>
      <section className="pt-50">
        <div className="container">
          <h2 className="mvh-secfooter">
            Pay
            <img src="/icons/cash.svg" className="svg-mvh-sec"  alt=""/>
          </h2>
          <h3 className="dtrp-secfooter">
            We Support this payment methods. You Can Choose any to pay.
          </h3>
          <div className="row paperx-product-swipe payboxes-mar pt-20">
            {methods}
          </div>
        </div>
      </section>
    </>
  );
};
export default Pay;
