import Layout from "../Layout";
import Fixed from "../Components/Fixed";
import ReviewsComponent from "../Components/Reviews"

function Reviews() {
  return (
    <Layout>
      <ReviewsComponent />
      <Fixed />
    </Layout>
  );
}

export default Reviews;
