const About = () => {
  return (
    <>
      <section className="pt-50">
        <div className="container">
          <h2 className="mvh-secfooter">About Company</h2>
          <h3 className="dtrp-secfooter">
            Highest quality items. We believe that our customers deserve the
            best.
          </h3>
          <div className="row pt-50 justify-content-center">
            <div className="col-lg-6">
              <p className="about-para">
                We understand the importance of customer satisfaction, which is
                why we make it a priority to offer only the best products,
                coupled with exceptional customer service. Our dedicated team of
                professionals is always available to answer any questions you
                may have and assist you in finding the perfect product for your
                needs.
              </p>
              <p className="about-para">
                In addition to offering a great selection of products, we also
                strive to make the shopping experience as seamless and
                <b>stress-free</b> as possible. Our user-friendly website is
                designed to be easy to navigate, and our secure checkout process
                ensures that your personal information is kept safe and secure.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
