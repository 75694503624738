import { useEffect } from "react";
const Slider = () => {
  useEffect(() => {
    const bar = document.getElementById("video-progress-slider");
    setTimeout(() => {
      bar.classList.add("w-100-per");
    }, 1000);
  }, []);
  return (
    <>
      <div className="slider-video">
        <div className="progrees-vide" id="video-progress-slider"></div>
        <video
          id="video-slider"
          muted
          loop
          src="/assets/sliders/slide-show.mp4"
          autoPlay
          playsInline
        >
          <source
            src="/assets/sliders/slide-show.mp4"
            type="video/mp4"
          ></source>
        </video>
      </div>
    </>
  );
};
export default Slider;
